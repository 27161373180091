import React from "react"
import Player from "../../blocks/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "lasha" }) {
    ...PlayerInfo
  }
  stats: allStatsYaml {
    nodes {
      key
      value_ru
      average
    }
  }
}`

export default class Lasha extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Сильная левая нога, позволяющая исполнять передачи любой дальности. Легко набирает высокую скорость как без мяча, так и с ним. Всегда предлагает себя на фланге под развитие атаки, готов к получению мяча, когда игра приближается к его зоне. Неплохие навыки плеймейкера для своей позиции, что позволяет ему при необходимости занимать пост левого опорного хавбека.</p>
          <p>За счет подвижности и видения игры компенсирует в обороне недостаток габаритов. Занимает правильную позицию для того, чтобы подачи с его фланга не доходили до штрафной.</p>
          <p>Умеет ввести мяч из аута, исполняет штрафные и угловые. Манера исполнения — крученая и резкая.</p>
          <p>В матче Грузия — Белоруссия два мяча были забиты после исполнения им угловых с правого края. Еще один мяч после такого же его стандарта Грузия забила Эстонии.</p>
          <div className="video">
            <iframe
              title={ this.fullName() }
              src="https://www.youtube.com/embed/1GW4rtmJKn8"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}